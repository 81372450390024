<template>
  <div class="page_main">
    <page-header></page-header>
    <router-view></router-view>
    <column />
  </div>
</template>

<script>
import pageHeader from "components/header/header";
import column from "components/layout/Column";

export default {
  name: 'IndexView',
  components: {
    pageHeader,
    column
  }
}
</script>
<style lang="scss" scoped>
.page_main{
  height: 100vh;
  background: url(~static/images/pageBg.jpg) no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 0 1vw 0;
}
</style>
