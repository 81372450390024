<template>
  <div class="swiper-slide">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name:'swiperSlide',
  
}
</script>

<style>

</style>