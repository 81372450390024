export const common = {
  computed: {
    time() {
      const nowDate = new Date()
      const hour =
        nowDate.getHours() < 10
          ? '0' + nowDate.getHours()
          : nowDate.getHours()
      const minute =
        nowDate.getMinutes() < 10
          ? '0' + nowDate.getMinutes()
          : nowDate.getMinutes()
      return `${hour}:${minute}`
    },
    nowDate(){
      const nowDate = new Date()
      const year = nowDate.getFullYear()
      const month = nowDate.getMonth() + 1
      const day = nowDate.getDate()
      
      return `${year}年${month}月${day}`
    },
    week() {
      const nowDate = new Date()
      const week = ['日', '一', '二', '三', '四', '五', '六']
      return week[nowDate.getDay()]
    }
  },
  filters:{
    originalImage(url) {
      const newUrl = url.replace('.jpg','_d.jpg');
      return newUrl
    }
  },
}