import pageBody from "components/layout/PageBody";
import contentBox from 'components/contentBox/ContentBox'
import swiper from "components/swiper/Swiper"
import swiperSlide from "components/swiper/SwiperSlide"

export default (Vue)=>{
  Vue.component('pageBody',pageBody)
  Vue.component('contentBox',contentBox)
  Vue.component('swiperBox',swiper),
  Vue.component('swiperSlide',swiperSlide)    
}