import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Home,
    redirect:'/home',
    children:[
      {
        path: '/life',
        name: 'live',
        component: () => import(/* webpackChunkName: "live" */ 'views/live/Live'),
        redirect:'/life/planning',
        children:[
          {
            path: '/life/planning',
            name: 'planning',
            component: () => import(/* webpackChunkName: "live" */ 'views/live/Planning')
          },
          {
            path: '/life/style',
            name: 'style',
            component: () => import(/* webpackChunkName: "live" */ 'views/live/Style')
          },
          {
            path: '/life/information',
            name: 'information',
            component: () => import(/* webpackChunkName: "live" */ 'views/live/Information')
          },
        ]
      },
      {
        path: '/culture',
        name: 'culture',
        component: () => import(/* webpackChunkName: "culture" */ 'views/culture/Culture'),
        redirect:'/culture/integral',
        children:[
          {
            path: '/culture/credit',
            name: 'credit',
            component: () => import(/* webpackChunkName: "culture" */ 'views/culture/Credit')
          },
          {
            path: '/culture/integral',
            name: 'integral',
            component: () => import(/* webpackChunkName: "culture" */ 'views/culture/Integral')
          },
          {
            path: '/culture/integralMall',
            name: 'integralMall',
            component: () => import(/* webpackChunkName: "benefit" */ 'views/culture/IntegralMall')
          },
        ]
      },
      {
        path: '/benefit',
        name: 'benefit',
        component: () => import(/* webpackChunkName: "benefit" */ 'views/benefit/Benefit'),
        redirect:'/benefit/help',
        children:[
          {
            path: '/benefit/business',
            name: 'business',
            component: () => import(/* webpackChunkName: "benefit" */ 'views/benefit/Business')
          },
          {
            path: '/benefit/help',
            name: 'help',
            component: () => import(/* webpackChunkName: "benefit" */ 'views/benefit/Help')
          },
          {
            path: '/benefit/garbage',
            name: 'garbage',
            component: () => import(/* webpackChunkName: "culture" */ 'views/benefit/Garbage')
          },
          
        ]
      },
      {
        path: '/govern',
        name: 'govern',
        component: () => import(/* webpackChunkName: "govern" */ 'views/govern/Govern'),
        redirect:'/govern/exposure',
        children:[
          {
            path: '/govern/exposure',
            name: 'exposure',
            component: () => import(/* webpackChunkName: "govern" */ 'views/govern/Exposure')
          },
          {
            path: '/govern/equipment',
            name: 'equipment',
            component: () => import(/* webpackChunkName: "govern" */ 'views/govern/Equipment')
          },
          {
            path: '/govern/emphasis',
            name: 'emphasis',
            component: () => import(/* webpackChunkName: "govern" */ 'views/govern/Emphasis')
          },
          {
            path: '/govern/grassRoots',
            name: 'grassRoots',
            component: () => import(/* webpackChunkName: "govern" */ 'views/govern/GrassRoots')
          },
        ]
      },
      {
        path: '/detail/:id',
        name: 'detail',
        component: () => import(/* webpackChunkName: "detail" */ 'views/detail/Detail')
        
      },
    ]
  },
  {
    path: '/mall',
    name: 'mall',
    component: () => import(/* webpackChunkName: "mall" */ 'views/mall/Mall')
  },
  {
    path: '/agricultural',
    name: 'agricultural',
    component: () => import(/* webpackChunkName: "agricultural" */ 'views/agricultural/Agricultural')
  },
  {
    path: '/environment',
    name: 'environment',
    component: () => import(/* webpackChunkName: "environment" */ 'views/environment/Environment')
  },
  {
    path: '/emergency',
    name: 'emergency',
    component: () => import(/* webpackChunkName: "emergency" */ 'views/emergency/Emergency')
  },
  {
    path: '/origin',
    name: 'origin',
    component: () => import(/* webpackChunkName: "origin" */ 'views/origin/Origin')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ 'views/Home')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
