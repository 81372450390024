import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    routeList:[],
    addScore:[],
    minusScore:[]
  },
  getters: {
    
  },
  mutations: {
    setRouteList(state,list) {
      state.routeList = list
    },
    setAddScore(state,list) {
      state.addScore = list
    },
    setMinusScore(state,list) {
      state.minusScore = list
    }
  },
  actions: {
  },
  modules: {
  }
})
