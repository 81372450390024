import { axios } from './request'

export function get(parameter) {
  return axios({
    url: parameter.url || parameter,
    method: 'GET',
    params: parameter.data || {}
  })
}

export function get_data(parameter) {
  return axios({
    url:'/api.php',
    method: 'GET',
    params: {act:'get_data',...parameter}
  })
}
// ------文章-------  //

// 文章列表
// class_id:文章分类ID
// user_id:会员ID(可选)
// key:关键词(可选,用于筛选)
// page:页数0全部
// size:返回条数0全部
export function article_list(parameter) {
  return axios({
    url:'/api.php',
    method: 'GET',
    params: {act:'article_list',...parameter}
  })
}
// 文章详情
// id:文章id
export function get_article(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'get_article',...parameter}
  })
}
// 文章分类
// class_id:45
export function article_class(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'article_class',...parameter}
  })
}
// 获得天气数据
// city:'成都'
export function tianqi(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'tianqi',...parameter}
  })
}


// ------积分商城-------  //

// 积分商品列表
// key:关键词(用于筛选)
// paixu:排序方式(不传=综合排序=默认排序)
// 积分递减jifenjian
// 积分递增jifenzeng
// page:页数0全部
// size:返回条数0全部
export function goods_list(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'goods_list',...parameter}
  })
}
//积分排名
export function score_list(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'score_list',...parameter}
  })
}
// 积分日志/诚信指数日志
export function score_log(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'score_log',...parameter}
  })
}
// 积分日志/诚信指数日志
export function order_list(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'order_list',...parameter}
  })
}

// ------大屏数据-------  //
// 乡村规划
export function xiangcunguihua_data(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'xiangcunguihua_data',...parameter}
  })
}
// 乡村风貌
export function xiangcunfengmao_data(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'xiangcunfengmao_data',...parameter}
  })
}
// 文明诚信
export function xiangcunwenming_data(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'xiangcunwenming_data',...parameter}
  })
}
// 诚信加减分
// 时间类型type:nian/yue/jidu,值value
export function chengxinjiajianfeng(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'chengxinjiajianfeng',...parameter}
  })
}
// 重点人员
export function zhongdianrenyuan_data(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'zhongdianrenyuan_data',...parameter}
  })
}
// 积分兑换数据
export function jifenduihuan(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'jifenduihuan',...parameter}
  })
}
// 积分商城
export function jifenshangcheng_data(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'jifenshangcheng_data',...parameter}
  })
}
// 曝光台
export function baoguangtai_data(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'baoguangtai_data',...parameter}
  })
}
// 互帮互助
export function hubanghuzhu_data(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'hubanghuzhu_data',...parameter}
  })
}

// 帮扶信息
export function bangfu_list(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'bangfu_list',...parameter}
  })
}
// 重点人员列表
export function users_list(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'users_list',...parameter}
  })
}
// 用户垃圾分类积分
export function lajifenleijifen(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'lajifenleijifen',...parameter}
  })
}
// 用户垃圾分类数据
export function lajifenleishuju(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'lajifenleishuju',...parameter}
  })
}


// 客流统计
export function visitor_traffic(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'visitor_traffic',...parameter}
  })
}
// 实时客流统计
export function guest_traffic(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'real_time_visitor_traffic',...parameter}
  })
}
// 截止昨日客流
export function total_visitor_traffic(parameter) {
  return axios({
    method: 'GET',
    url:'/api.php',
    params: {act:'total_visitor_traffic',...parameter}
  })
}

// 今日人员进出数据
export function get_person(parameter) {
  return axios({
    url:'/api.php',
    params: {act:'real_time_visitor_traffic',...parameter}
  })
}
// 今日车辆进出数据
export function get_car(parameter) {
  return axios({
    url:'/api.php',
    params: {act:'real_time_vehicle_traffic',...parameter}
  })
}
// 周界闯入报警
export function get_alarm(parameter) {
  return axios({
    url:'/api.php',
    params: {act:'event_warning',...parameter}
  })
}
