<template>
  <div class="page_body">
    <div class="page_body_left" :style="{width:leftWidth}">
      <slot name="pageLeft"></slot>
    </div>
    <div class="page_body_center" :style="{width:centerWidth}" v-if="$slots.pageCenter">
      <slot name="pageCenter"></slot>
    </div>
    <div class="flex_1 page_body_right">
      <slot name="pageRight"></slot>
    </div>
  </div>
</template>

<script>


export default {
  name:'pageBody',
  data(){
    return {
    }
  },
  props: {
    leftWidth: {
      type: String,
      default: '23.9583%'
    },
    centerWidth: {
      type: String,
      default: '0%'
    }
  },
  
}
</script>

<style lang="scss" scoped>
.page_body{
  display: flex;
  padding: 1vw 0.5vw 0;
  .page_body_left{
    width: 23.9583%;
    margin: 0 1.04vw;
  }
  .page_body_center{
    margin: 0 1.04vw 0 0;
  }
  .page_body_right{
    margin: 0 1.04vw 0 0;
    overflow: hidden;
  }
}
</style>